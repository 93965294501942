import PubSub from 'pubsub-js';
import SwiperJS from 'node_modules/swiper/swiper-bundle.js';
import Console from '../common/Console.js';


export default class Swiper {

	constructor (selector) {

		this.selector = selector;

		this.DOM = {};

		this.DOM.element = document.querySelector(selector);

		this.options =
		{
			// slides
			loop: true,
			slidesPerView: 1,
			centeredSlides: false,
			spaceBetween: 0,
			roundLengths: true,

			// interactivity
			grabCursor: true,
			mousewheel: false,
			preventInteractionOnTransition: false,

			// keyboard
			keyboard:
			{
				enabled: true,
			},

			// lazyload
			preloadImages: false,
			lazy:
			{
				loadPrevNext: true,
				loadPrevNextAmount: 1,
				loadOnTransitionStart: true,
			},

			// pagination
			pagination:
			{
				el: this.selector + ' .swiper-pagination',
				type: 'fraction',
			},

			// navigation
			navigation:
			{
				nextEl: this.selector + ' .swiper-button-next',
				prevEl: this.selector + ' .swiper-button-prev',
			},

			// autoplay
			autoplay:
			{
				delay: 2000,
				disableOnInteraction: false,
				waitForTransition: true,
			}

		};

		this.setTransition('slide');

	}

	/*
	** set
	*/

	setOption (key, value) {

		this.options[key] = value;

	}

	setTransition (value) {

		switch (value)
		{
			case 'slide':
				this.options.effect = 'slide';
				this.options.speed  = 800;
				this.options.simulateTouch = true;
				break;

			case 'fade':
				this.options.effect = 'fade';
				this.options.speed  = 800;
				this.options.simulateTouch = false;
				break;

			case 'cut':
				this.options.effect = 'cut';
				this.options.speed = 0;
				this.options.simulateTouch = false;
				break;
		}

	}

	/*
	** play
	*/

	play (value) {

		if (value)
		{
			this.autoplay(true);

			if (this.DOM.play !== null)
			{
				this.DOM.play.classList.remove('paused');
			}

		}
		 else
		{
			this.autoplay(false);

			if (this.DOM.play !== null)
			{
				this.DOM.play.classList.add('paused');
			}
		}

	}

	autoplay (value) {

		if (this.swiper === undefined)
		{
			return false;
		}

		if (value)
		{
			this.swiper.autoplay.start();
		}
		 else
		{
			this.swiper.autoplay.stop();
		}

		this.isPlayed = value;

	}

	/*
	** slideTo
	*/

	slideTo (index, speed) {

		if (this.swiper === undefined)
		{
			return false;
		}

		if (speed === undefined)
		{
			speed = this.options.speed;
		}

		this.swiper.slideToLoop(index, speed);

	}

	/*
	** create
	*/

	create() {

		// slides
		this.DOM.slides = this.DOM.container.querySelectorAll('.swiper-slide');

		// count
		this.count = this.DOM.slides.length;

		// no slides
		if (!this.count)
		{
			return false;
		}

		// only 1 slide
		if (this.count == 1)
		{
			this.options.loop = false;
			this.options.simulateTouch = false;
			this.options.grabCursor = false;
		}

		// swiper
		this.swiper = new SwiperJS(this.selector + ' .swiper-container', this.options);

		// events
		this.swiper.on('lazyImageReady', (swiper, slideEl, imageEl) =>
		{
			slideEl.classList.add('loaded');
		});

		this.swiper.on('slideChange', () =>
		{
			PubSub.publish('swiper.slideChange', {index: this.swiper.realIndex});
		});

		// next
		this.nextSlideOnClick();

		// play
		this.DOM.play = this.DOM.element.querySelector('.swiper-button-play');

		if (this.DOM.play !== null)
		{
			this.DOM.play.addEventListener('click', event =>
			{
				if (this.isPlayed)
				{
					this.play(false);
				}
				 else
				{
					this.play(true);
				}

			}, false);
		}

		this.play(false);

		// publish
		PubSub.publish('swiper.slideChange', {index: 0});

	}

	/*
	** nextSlideOnClick
	*/

	nextSlideOnClick() {

		if (this.count < 2)
		{
			return false;
		}

		for (let i = 0; i < this.count; ++i)
		{
			const el = this.DOM.slides[i];

			const overlayEl = el.querySelector('.swiper-overlay');

			if (overlayEl !== null)
			{
				overlayEl.addEventListener('click', event =>
				{
					this.swiper.slideNext();

					event.preventDefault();

				}, false);
			}
		}

	}

	/*
	** load
	*/

	load() {

		Console.log('Swiper::load');

		// element
		if (this.DOM.element === null)
		{
			return false;
		}

		// margin
		const transition = this.DOM.element.getAttribute('data-swiper-transition');

		if (transition !== null)
		{
			this.setTransition(transition);
		}

		// delay
		const delay = this.DOM.element.getAttribute('data-swiper-delay');

		if (delay !== null)
		{
			this.options.autoplay.delay = parseInt(delay);
		}

		// container
		this.DOM.container = this.DOM.element.querySelector('.swiper-container');

		if (this.DOM.container === null)
		{
			return false;
		}

		// create
		this.create();

	}

	/*
	** unload
	*/

	unload() {

		Console.log('Swiper::unload');

		// swiper
		if (this.swiper !== undefined)
		{
			this.swiper.destroy();
		}

	}

}