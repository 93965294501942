import Console   from './common/Console.js';
import PageEvent from './common/PageEvent.js';
import Util      from './common/Util.js';
import Loading   from './common/Loading.js';


export default class Navigation extends PageEvent {

	constructor() {

		super();

		this.DOM = {};

		this.DOM.body = document.body;

		this.works = [];

	}

	/*
	** setActive
	*/

	static setActive (controller) {

		Console.log('Navigation::setActive('+ controller +')');

		// sections
		const elements = document.querySelectorAll('.sections__anchor');

		for (let i = 0; i < elements.length; ++i) 
		{
			elements[i].classList.remove('selected');
		}

		// section
		const sectionEl = document.querySelector('.sections__anchor[data-controller="'+ controller +'"]');

		if (sectionEl !== null)
		{
			sectionEl.classList.add('selected');
		}

		// title
		const headerSectionEl = document.querySelector('.header__section');

		const footerSectionEl = document.querySelector('.footer__section');

		const pageNameEl = document.querySelector('.page__name');

		if (pageNameEl !== null)
		{
			headerSectionEl.innerHTML = pageNameEl.getAttribute('data-name-format');
			footerSectionEl.innerHTML = pageNameEl.innerHTML;
		}
		 else
		{
			headerSectionEl.innerHTML = '';
			footerSectionEl.innerHTML = '';
		}

	}

	/*
	** init
	*/

	init() {

		// burger
		this.initBurger();

		// overlay
		this.initOverlay();

	}

	initBurger() {

		const burger = document.querySelector('.header__burger');

		burger.addEventListener('click', event =>
		{
			if ( !this.DOM.container.classList.contains('header--open') )
			{
				this.open();
			}
			 else
			{
				this.close();
			}

			event.preventDefault();

		}, false);

	}

	initOverlay() {

		const overlay = document.querySelector('.flap__overlay');

		overlay.addEventListener('click', event =>
		{
			this.close();

			event.preventDefault();

		}, false);

	}

	/*
	** color
	*/

	static color() {

		const colors = [
			'#99cccc',
			'#ff6600',
			'#ff3300',
			'#E122AE',
			'#66ff99',
			'#ff6666'
		];

		const color = colors[Math.floor(Math.random() * colors.length)];

		document.body.style.color = color;

		Console.log('Transition::color('+ color +')');

	}

	/*
	** open
	*/

	open() {

		this.DOM.container.classList.add('header--open');

	}

	/*
	** close
	*/

	close() {

		this.DOM.container.classList.remove('header--open');

		this.DOM.container.classList.remove('header--hover');

	}

	/*
	** scroll
	*/

	scroll (event) {

		super.scroll(event);

		if ( Util.getScrollTop() > 10 )
		{
			this.DOM.body.classList.add('body--scrolled');
		}
		 else
		{
			this.DOM.body.classList.remove('body--scrolled');
		}

		if ( Util.getScrollTop() > (this.windowHeight - 10) )
		{
			this.DOM.body.classList.add('body--xscrolled');
		}
		else
		{
			this.DOM.body.classList.remove('body--xscrolled');
		}

	}

	/*
	** start
	*/

	start() {

		Console.log('Navigation::start');

		// elements
		this.DOM.container = document.querySelector('.header');

		// load
		this.load();

		// init
		this.init();

		// resize
		this.resize();

		// color
		Navigation.color();

	}

}