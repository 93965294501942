import { createRouter } from 'routerjs';

import PubSub     from 'pubsub-js';
import Path       from './common/Path.js';
import Console    from './common/Console.js';
import Navigation from './Navigation.js';
import Transition from './Transition.js';


export default class App {

	constructor (baseUrl = '', debug = false) {

		Path.setBaseUrl(baseUrl);

		Console.setDebug(debug);

		this.isRouter = true;

	}

	/*
	** start
	*/

	start() {

		// navigation
		this.navigation = new Navigation();
		this.navigation.start();

		// transition
		this.transition = new Transition();

		PubSub.subscribe('transition.load', (msg, data) =>
		{
			this.navigation.close();
		});

		this.transition.start();

		// router
		this.router(this.isRouter);

	}

	/*
	** router
	*/

	router (isRouter=false) {

		if (isRouter)
		{
			let isPopState = false;

			// scrollrestauration
			if ('scrollRestoration' in history) 
			{
				history.scrollRestoration = 'manual';
			}

			// events
			window.addEventListener('popstate', (event) => {
				isPopState = true;
				return false;
			});

			// router
			const router = createRouter();

			// home
			router.get('/', (request, context) => {
				this.transition.load('home', request, isPopState);
				isPopState = false;
			});

			router.get('/index.php', (request, context) => {
				this.transition.load('home', request, isPopState);
				isPopState = false;
			});

			router.get('/fr', (request, context) => {
				this.transition.load('home', request, isPopState);
				isPopState = false;
			});

			router.get('/en', (request, context) => {
				this.transition.load('home', request, isPopState);
				isPopState = false;
			});

			// image
			router.get('/:lang/image', (request, context) => {
				this.transition.load('works', request, isPopState);
				isPopState = false;
			});

			router.get('/:lang/image/:page', (request, context) => {
				this.transition.load('works', request, isPopState);
				isPopState = false;
			});

			// production
			router.get('/:lang/realisation', (request, context) => {
				this.transition.load('works', request, isPopState);
				isPopState = false;
			});

			router.get('/:lang/realisation/:page', (request, context) => {
				this.transition.load('works', request, isPopState);
				isPopState = false;
			});

			// stage
			router.get('/:lang/mise-en-scene', (request, context) => {
				this.transition.load('works', request, isPopState);
				isPopState = false;
			});

			router.get('/:lang/mise-en-scene/:page', (request, context) => {
				this.transition.load('works', request, isPopState);
				isPopState = false;
			});

			// art
			router.get('/:lang/art', (request, context) => {
				this.transition.load('works', request, isPopState);
				isPopState = false;
			});

			router.get('/:lang/art/:page', (request, context) => {
				this.transition.load('works', request, isPopState);
				isPopState = false;
			});

			// work
			router.get('/fr/projet/:slug', (request, context) => {
				this.transition.load('work', request, isPopState);
				isPopState = false;
			});

			router.get('/fr/projet/:slug/:page', (request, context) => {
				this.transition.load('work', request, isPopState);
				isPopState = false;
			});

			router.get('/en/project/:slug', (request, context) => {
				this.transition.load('work', request, isPopState);
				isPopState = false;
			});

			router.get('/en/project/:slug/:page', (request, context) => {
				this.transition.load('work', request, isPopState);
				isPopState = false;
			});

			// about
			router.get('/fr/a-propos', (request, context) => {
				this.transition.load('about', request, isPopState);
				isPopState = false;
			});

			router.get('/en/about', (request, context) => {
				this.transition.load('about', request, isPopState);
				isPopState = false;
			});

			// run
			router.run();

		}
		 else
		{
			this.transition.buildFirst();
		}

	}

}