import Console from './Console.js';


export default class Preloading {

	/*
	** show
	*/

	static show() {

		Console.log('Preloading::Show');

	}

	/*
	** hide
	*/

	static hide (callback) {

		Console.log('Preloading::hide');

		const preloaderEl = document.querySelector('.preloader');

		setTimeout(function()
		{
			// hide
			preloaderEl.classList.add('preloader--hide');

			setTimeout( () =>
			{
				// hidden
				preloaderEl.classList.add('preloader--hidden');

				// callback
				if ( callback && typeof(callback) === 'function' )
				{
					callback();
				}

			}, 800);

		}, 800);

	}

}