import PubSub from 'pubsub-js';
import Util from '../common/Util.js';

export default class SwiperVideoSlide {

	constructor (element) {

		this.DOM = {el : element};

		// bounds
		this.boundVideoLoadedData = (event) => this.onVideoLoadedData(event);

	}

	/*
	** load
	*/

	load() {

		// image
		this.DOM.image = this.DOM.el.querySelector('.swiper-image');

		if (this.DOM.image === null)
		{
			return false;
		}

		// src
		this.src = this.DOM.el.getAttribute('data-mp4');

		// video
		this.DOM.video = Util.getVideoElement(this.src);

		this.DOM.video.addEventListener('loadeddata', this.boundVideoLoadedData);

		this.DOM.image.appendChild(this.DOM.video);

	}

	onVideoLoadedData (event) {

		this.DOM.el.classList.add('loadeddata');

	}

	/*
	** unload
	*/

	unload() {

		// video
		if (this.DOM.video !== undefined)
		{
			this.DOM.video.removeEventListener('loadeddata', this.boundVideoLoadedData);
		}

		// class
		this.DOM.el.classList.remove('loadeddata');

	}

}