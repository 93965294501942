import MyPage from '../module/MyPage.js';
import Images from '../module/Images.js';


export default class About extends MyPage {

	constructor() {

		super();

		this.name = 'about';

	}

	init() {

		// images
		this.images = new Images();
		this.images.load();

		// super
		super.init();

	}

	/*
	** unload
	*/

	unload() {

		// images
		this.images.unload();

		// super
		super.unload();

	}
}