
export default class Navigate {

	constructor() {

		this.DOM = {};

	}

	/*
	** init
	*/

	init () {

		// back
		this.DOM.back = document.querySelector('.navigate__back');
		this.DOM.backAnchor  = this.DOM.back.querySelector('.back__anchor');

		// prev
		this.DOM.prev = document.querySelector('.navigate__prev');
		this.DOM.prevAnchor = this.DOM.prev.querySelector('.prev__anchor');

		// next
		this.DOM.next = document.querySelector('.navigate__next');
		this.DOM.nextAnchor = this.DOM.next.querySelector('.next__anchor');
	}

	/*
	** back
	*/

	back (url, page) {

		if (url)
		{
			if (page && (page > 1) )
			{
				url += '/' + page;
			}

			this.DOM.backAnchor.setAttribute('href', url);
		}

	}

	/*
	** prev
	*/

	prev (url) {

		if (url)
		{
			this.DOM.prev.style.display = 'block';

			this.DOM.prevAnchor.setAttribute('href', url);
		}
		 else
		{
			this.DOM.prev.style.display = 'none';

			this.DOM.prevAnchor.setAttribute('href', '#');
		}

	}

	/*
	** next
	*/

	next (url) {

		if (url)
		{
			this.DOM.next.style.display = 'block';

			this.DOM.nextAnchor.setAttribute('href', url);
		}
		 else
		{
			this.DOM.next.style.display = 'none';

			this.DOM.nextAnchor.setAttribute('href', '#');
		}

	}

	/*
	** load
	*/

	load() {

		this.init();

	}

	/*
	** unload
	*/

	unload() {


	}

}