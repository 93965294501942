import Util from '../common/Util.js';
import SmoothPage from './SmoothPage.js';


export default class MyPage extends SmoothPage {

	constructor() {

		super();

	}

	/*
	** init
	*/

	init() {

		// super
		super.init();

	}

	/*
	** enter
	*/

	enter() {

		// super
		super.enter();

		// class
		setTimeout( () =>
		{
			this.DOM.body.classList.add('body--page-start');

		}, 600);

		this.DOM.body.classList.add('body--page-enter');

	}

	/*
	** unload
	*/

	unload() {

		// super
		super.unload();

		// class
		this.DOM.body.classList.remove('body--page-enter');
		this.DOM.body.classList.remove('body--page-start');

	}

}