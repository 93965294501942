import Util from '../common/Util.js';
import MyPage from '../module/MyPage.js';
import Images from '../module/Images.js';
import Grid from '../module/Grid.js';
import VideoThumbnails from '../module/VideoThumbnails.js';


export default class Home extends MyPage {

	constructor() {

		super();

		this.name = 'home';

	}

	/*
	** init
	*/

	init() {

		// images
		this.images = new Images();
		this.images.load();

		// grid
		this.grid = new Grid();
		this.grid.load();

		// video thumbnails
		this.videoThumbnails = new VideoThumbnails();
		this.videoThumbnails.load();

		// overlay
		this.initIntroduction();

		// super
		super.init();

	}

	initIntroduction() {

		const overlayEl = document.querySelector('.introduction__overlay');

		if (overlayEl !== null)
		{
			overlayEl.style.cursor = 'pointer';

			overlayEl.addEventListener('click', event =>
			{
				Util.scrollToElement ('.home__works', 1000);

				event.preventDefault();

			}, false);
		}

	}

	/*
	** enter
	*/

	enter() {

		super.enter();

	}

	/*
	** unload
	*/

	unload() {

		// images
		this.images.unload();

		// grid
		this.grid.unload();

		// video thumbnails
		this.videoThumbnails.unload();

		// super
		super.unload();

	}
}