import Util from '../common/Util.js';

export default class Image {

	constructor (element) {

		this.DOM = { el: element };

		this.DOM.vignette = this.DOM.el.querySelector('.vignette');

		this.isVideo = false;

		this.isCreated = false;

		// bounds
		this.boundEventResize = Util.debounce(event  => this.resize(event), 500);

		this.boundEventScroll = Util.debounce(scroll => this.scroll(event), 500);

	}

	/*
	** resize
	*/

	resize (event) {

		this.playVideosInViewport();

	}

	/*
	** scroll
	*/

	scroll (event) {

		this.playVideosInViewport();

	}

	/*
	** video
	*/

	playVideosInViewport() {

		if ( Util.isPartInViewport(this.DOM.el) )
		{
			this.createLoop();
		}
		 else
		{
			this.destroyLoop();
		}

	}

	/*
	** loop
	*/

	initLoop () {

		this.isVideo = this.DOM.el.getAttribute('data-video');

		if (this.isVideo)
		{
			this.src = this.DOM.el.getAttribute('data-mp4');

			this.DOM.el.classList.add('image--video');
		}

	}

	createLoop () {

		if (!this.isVideo)
		{
			return false;
		}

		if (this.isCreated)
		{
			return false;
		}

		// video
		const videoEl = Util.getVideoElement(this.src);

		this.DOM.vignette.appendChild(videoEl);

		this.isCreated = true;

	}

	destroyLoop () {

		if (!this.isVideo)
		{
			return false;
		}

		if (!this.isCreated)
		{
			return false;
		}

		const loopEl = this.DOM.vignette.querySelector('video');

		if (loopEl !== null)
		{
			loopEl.remove();
		}

		this.isCreated = false;

	}

	/*
	** loop
	*/

	loop () {

		this.createLoop();

	}

	/*
	** load
	*/

	load () {

		// init
		this.initLoop();

		// play
		this.playVideosInViewport();

		// resize
		window.addEventListener('resize', this.boundEventResize);
		window.addEventListener('orientationchange', this.boundEventResize);

		// scroll
		window.addEventListener('scroll', this.boundEventScroll);

	}

	/*
	** unload
	*/

	unload () {

		// destroy
		this.destroyLoop();

		// resize
		window.removeEventListener('resize', this.boundEventResize);
		window.removeEventListener('orientationchange', this.boundEventResize);

		// scroll
		window.removeEventListener('scroll', this.boundEventScroll);

	}

}