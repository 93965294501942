import Util from '../common/Util.js';

export default class VideoThumbnail {

	constructor (element) {

		this.DOM = { el: element };

		this.DOM.figure = this.DOM.el.querySelector('figure');

		this.isVideo = false;

	}

	/*
	** create / destroy
	*/

	create () {

		if (!this.isVideo)
		{
			return false;
		}

		// video
		this.DOM.video = Util.getVideoElement(this.src);

		this.DOM.figure.appendChild(this.DOM.video);

	}

	destroy () {

		if (!this.isVideo)
		{
			return false;
		}

		if (this.DOM.video !== undefined)
		{
			this.DOM.video.remove();
			this.DOM.video = undefined;
		}

	}

	/*
	** load
	*/

	load () {

		this.isVideo = parseInt(this.DOM.el.getAttribute('data-video'));

		if (!this.isVideo)
		{
			return false;
		}

		// init
		this.src = this.DOM.el.getAttribute('data-mp4');

		// events
		this.DOM.el.addEventListener('mouseenter', event =>
		{
			this.create()

		}, false);

		this.DOM.el.addEventListener('mouseleave', event =>
		{
			this.destroy()

		}, false);

	}

	/*
	** unload
	*/

	unload () {

		this.destroy();

	}

}