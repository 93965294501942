import PubSub from 'pubsub-js';
import Util from '../common/Util.js';
import Navigator from '../class/Navigator.js';
import MyPage from '../module/MyPage.js';
import Grid from '../module/Grid.js';
import VideoThumbnails from '../module/VideoThumbnails.js';


export default class Works extends MyPage {

	constructor() {

		super();

		this.name = 'works';

		this.isMobile = Util.isMobile();

		this.boundPageReady = (msg, data) => this.pageReady();

	}

	/*
	** init
	*/

	init() {

		// pubsub
		this.pubsubBoundPageReady = PubSub.subscribe('page.ready', this.boundPageReady);

		// controller
		this.controller = this.DOM.container.getAttribute('data-controller');

		// navigator
		this.navigatorKey = 'works-' + this.controller;

		Navigator.recordFromNodeList(this.navigatorKey, '.works__item');

		// grid
		this.grid = new Grid();
		this.grid.load();

		// video thumbnails
		this.videoThumbnails = new VideoThumbnails();
		this.videoThumbnails.load();

		// super
		super.init();

	}

	/*
	** scroll to selected
	*/

	scrollToSelected() {

		const key = this.navigatorKey + '-id';

		const id = Navigator.getValue(key);

		if (!id)
		{
			return false;
		}

		const element = document.querySelector('.works__item[data-id="'+ id +'"]');

		if (element !== null)
		{
			const bounding = element.getBoundingClientRect();

			const top = bounding.top + ( (bounding.height - Util.windowHeight()) / 2);

			if (this.isMobile)
			{
				Util.scrollTo(top, 0);
			}
			 else
			{
				this.scrollTo(top);
			}
		}

	}

	/*
	** page ready
	*/

	pageReady() {

		this.scrollToSelected();

	}

	/*
	** unload
	*/

	unload() {

		// pubsub
		if (this.pubsubBoundPageReady !== undefined)
		{
			PubSub.unsubscribe(this.pubsubBoundPageReady);
		}

		// grid
		this.grid.unload();

		// video thumbnails
		this.videoThumbnails.unload();

		// super
		super.unload();

	}

}