import Console from '../common/Console.js';
import PageEvent from '../common/PageEvent.js';
import Util from '../common/Util.js';


export default class Grid extends PageEvent {

	constructor () {

		super();

		this.DOM = {};

		this.count = 0;

		this.rowHeight = 300;

		this.columnMax = 3;

		this.margin = 0;

		this.items = [];

	}

	/*
	** init
	*/

	init() {

		this.DOM.items = this.DOM.container.querySelectorAll('.grid__item');

		this.count = this.DOM.items.length;

		for (let i=0; i < this.count; i++)
		{
			const el = this.DOM.items[i];
			const img = el.querySelector('.img');

			// data
			const w = parseInt(img.getAttribute('width'));
			const h = parseInt(img.getAttribute('height'));
			const width = (w*this.rowHeight)/h;

			this.items.push(
			{
				'element': el,
				'img': img,
				'width': width,
				'oWidth': w,
				'oHeight': h
			});
		}

	}

	/*
	** column
	*/

	makeColumn() {

		// Console.log('Grid::makeColumn');

		// clear
		this.clearGrid();

		// for
		for (let i=0; i < this.count; i++)
		{
			const itemEl = this.items[i].element;
			const img = this.items[i].img;
			const w = this.items[i].oWidth;
			const h = this.items[i].oHeight;
			const width = itemEl.clientWidth;

			img.style.width = width+'px';
			img.style.height = ((width*h) / w) +'px';
		}

	}

	/*
	** grid
	*/

	makeGrid() {

		// Console.log('Grid::makeGrid');

		// clear
		this.clearGrid();

		const margin = (this.margin*this.DOM.container.clientWidth)/100;

		const containerWidth = this.DOM.container.clientWidth - (margin*(this.columnMax+1));

		var odd = 0;
		var col = 0;
		var row = [];
		var rowWidth = 0;
		var marginWidth = 0;

		for (let i=0; i < this.count; i++)
		{
			const item = this.items[i].element;
			const img = this.items[i].img;
			const width = this.items[i].width;

			// col
			col++;

			// odd
			if ( (i%this.columnMax) === 0 )
			{
				odd = (odd)? 0: 1;
			}

			// margin
			if (odd)
			{
				item.classList.add('left');
				item.style.marginRight = margin+'px';
				item.style.marginBottom = margin+'px';
			}
			 else
			{
				item.classList.add('right');
				item.style.marginLeft = margin+'px';
				item.style.marginBottom = margin+'px';
			}

			// img
			item.style.width = width+'px';
			img.style.width = width+'px';
			img.style.height = this.rowHeight+'px';

			// width
			rowWidth += this.items[i].width;
			marginWidth += margin;

			// push
			row.push(this.items[i]);

			// height
			if ( (col === this.columnMax) || (i === (this.count-1)) )
			{
				// adapt || calcul
				if ( (rowWidth+marginWidth) >= (containerWidth+margin) )
				{
					this.adaptRowHeight(row, rowWidth, containerWidth, odd);
				}
				 else
				{
					this.calculRowHeight(row, rowWidth, odd);
				}

				row = [];
				rowWidth = 0;
				marginWidth = 0;

				// separator
				const separator = document.createElement('div');
				separator.classList.add('grid__separator');
				item.after(separator);

				// col
				col = 0;
			}
		}

	}

	adaptRowHeight (row, rowWidth, containerWidth, margin) {

		const count = row.length;
		const marginWidth = margin * (count-1);
		const zoneWidth = containerWidth - marginWidth;
		const height = (zoneWidth*this.rowHeight) / rowWidth;

		for (let i=0; i<count; i++) 
		{
			const item = row[i].element;
			const img = row[i].img;
			const width = (row[i].width * height)/this.rowHeight;

			item.style.width = width+'px';
			img.style.width = width+'px';
			img.style.height = height+'px';
		}

	}

	calculRowHeight (row) {

		const count = row.length;

		for (let i=0; i<count; i++)
		{
			const itemEl = row[i].element;
			const img = row[i].img;
			const width = (row[i].width * this.rowHeight ) / row[i].height;

			itemEl.style.width = width+'px';
			img.style.width = width+'px';
			img.style.height = this.rowHeight+'px';
		}

	}

	clearGrid () {

		// class left / right
		for (let i=0; i < this.count; i++)
		{
			const item = this.items[i].element;

			item.classList.remove('.left');
			item.classList.remove('.right');
		}

		// separators
		const separators = this.DOM.element.querySelectorAll('.grid__separator');

		for (let i=0; i< separators.length; i++) 
		{
			separators[i].remove();
		}

	}

	/*
	** resize
	*/

	resize (event) {

		if (this.DOM.element === null)
		{
			return false;
		}

		if ( Util.isMobileWidth() )
		{
			this.makeColumn();
		}
		 else
		{
			this.makeGrid();
		}

	}

	/*
	** load
	*/

	load() {

		Console.log('Grid::load');

		// super
		super.load();

		// element
		this.DOM.element = document.querySelector('.grid');

		if (this.DOM.element === null)
		{
			return false;
		}

		// margin
		const margin = this.DOM.element.getAttribute('data-fullrow-margin');

		if (margin !== null)
		{
			this.margin = parseInt(margin);
		}

		// columnMax
		const columnMax = this.DOM.element.getAttribute('data-fullrow-column-max');

		if (columnMax !== null)
		{
			this.columnMax = parseInt(columnMax);
		}

		// rowHeight
		const rowHeight = this.DOM.element.getAttribute('data-fullrow-row-height');

		if (rowHeight !== null)
		{
			this.rowHeight = parseInt(rowHeight);
		}

		// container
		this.DOM.container = this.DOM.element.querySelector('.grid__container');

		if (this.DOM.container === null)
		{
			return false;
		}

		// init
		this.init();

		// resize
		this.resize();

	}

	/*
	** unload
	*/

	unload() {

		Console.log('Works::unload');

		// super
		super.unload();

	}

}