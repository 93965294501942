import Film from '../Film.js';


export default class WorkFilm {

	/*
	** load
	*/

	load() {

		// film
		this.film = new Film();
		this.film.load();

	}


	/*
	** unload
	*/

	unload() {

		// film
		this.film.unload();

	}

}