import anime from 'animejs/lib/anime.es.js';


export default class Util {

	static windowWidth() {

		return document.documentElement.clientWidth;

	}

	static windowHeight() {

		return document.documentElement.clientHeight;

	}

	static isSmallWidth() {

		return Util.isIpadWidth();

	}

	static isIpadWidth() {

		return (Util.windowWidth() <= 768);

	}

	static isMobileWidth() {

		return (Util.windowWidth() <= 640);

	}

	static isMobile() {

		if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
		{
			return true;
		}
		 else
		{
			return false;
		}

	}

	static getMousePos (event) {

		let posx = 0;
		let posy = 0;

		if (!event)
		{
			event = window.event;
		}

		if (event.pageX || event.pageY)
		{
			posx = event.pageX;
			posy = event.pageY;
		}
		else if (event.clientX || event.clientY)
		{
			posx = event.clientX + document.body.scrollLeft + document.documentElement.scrollLeft;
			posy = event.clientY + document.body.scrollTop + document.documentElement.scrollTop;
		}

		return { x : posx, y : posy }

	}

	static setScrollTop (value) {

		document.documentElement.scrollTop = value;
		document.body.scrollTop = value;

	}

	static getScrollHeight() {

		return Math.max(
			document.body.scrollHeight, document.documentElement.scrollHeight,
			document.body.offsetHeight, document.documentElement.offsetHeight,
			document.body.clientHeight, document.documentElement.clientHeight
		);

	}

	static getScrollTop() {

		return Math.max(
			window.pageYOffset,
			document.documentElement.scrollTop,
			document.body.scrollTop
		);

	}

	static scrollToTop() {

		document.documentElement.scrollTop = 0;
		document.body.scrollTop = 0;

	}

	static scrollToElement (selector, duration, callback) {

		let element = document.querySelector(selector);

		if (element !== null)
		{
			let top = element.offsetTop;

			Util.scrollTo(top, duration, callback);
		}

	}

	static scrollToCenterElement (selector, duration, callback) {

		let element = document.querySelector(selector);

		if (element !== null)
		{
			const bounding = element.getBoundingClientRect();
			const top = bounding.top + ( (bounding.height - Util.windowHeight()) / 2);

			Util.scrollTo(top, duration, callback);
		}

	}

	static scrollTo (top, duration, callback) {

		anime({
			targets   : 'html,body',
			scrollTop : top,
			easing    : 'easeInOutExpo',
			duration  : duration,
			complete  : anim =>
			{
				// callback
				if (callback && typeof(callback) === 'function')
				{
					callback();
				}
			}
		});

	}

	static debounce (callback, wait) {

		let timeout;

		return (...args) =>
		{
			const _this = this;

			clearTimeout(timeout);

			timeout = setTimeout(() => callback.apply(_this, args), wait);
		};

	}

	static isInViewport (element) {

		const bounding = element.getBoundingClientRect();

		return (
			bounding.top >= 0 &&
			bounding.left >= 0 &&
			bounding.bottom <= (document.documentElement.clientHeight || window.innerHeight) &&
			bounding.right  <= (document.documentElement.clientWidth  || window.innerWidth)
		);

	}

	static isPartInViewport (element) {

		const bounding = element.getBoundingClientRect();

		const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
		const windowWidth  = (window.innerWidth || document.documentElement.clientWidth);

		const vertInView = (bounding.top  <= windowHeight) && ((bounding.top  + bounding.height) >= 0);
		const horInView  = (bounding.left <= windowWidth)  && ((bounding.left + bounding.width)  >= 0);

		return (vertInView && horInView);

	}

	static vignette (selector) {

		const elements = document.querySelectorAll(selector);

		for (let i = 0; i < elements.length; ++i)
		{
			let element = elements[i];
			let img = element.querySelector('img');

			if (!img.getAttribute('data-original-width') && !img.getAttribute('data-original-height'))
			{
				img.setAttribute('data-original-width' , img.getAttribute('width'));
				img.setAttribute('data-original-height', img.getAttribute('height'));
			}

			let originalWidth = img.getAttribute('data-original-width');
			let originalHeight = img.getAttribute('data-original-height');
			let percent = Math.round((100*originalHeight)/originalWidth);

			element.style.paddingBottom = percent+'%';
		}

	}

	static getAdaptedSize (maxWidth, maxHeight, width, height, isFullscreen) {

		let size = {'width': 0, 'height': 0};
		let ph = height / width;
		let pw = width / height;

		if ((width > 0) && (height > 0))
		{
			let condition = (isFullscreen)? ((maxHeight / maxWidth) < ph): ((maxHeight / maxWidth) > ph);

			if (condition)
			{
				size.width = maxWidth;
				size.height = Math.round(ph*maxWidth);
			}
			else
			{
				size.width = Math.round(pw*maxHeight);
				size.height = maxHeight;
			}
		}

		return size;

	}

	static getVideoElement (src) {

		const videoEl = document.createElement('video');
		videoEl.muted = true;
		videoEl.loop = true;
		videoEl.autoplay = true;

		const sourceEl = document.createElement('source');
		sourceEl.src = '/files/' + src;
		sourceEl.type = 'video/mp4';
		videoEl.appendChild(sourceEl);

		return videoEl;

	}

	static addBlank (selector) {

		const elements = document.querySelectorAll(selector);

		for (let i = 0; i < elements.length; ++i)
		{
			let element = elements[i];

			const anchors = element.querySelectorAll("a[href^='http://'], a[href^='https://']");

			for (let j = 0; j < anchors.length; ++j)
			{
				anchors[j].setAttribute('target', '_blank');
			}
		}

	}

	static removeAlt (selector) {

		const elements = document.querySelectorAll(selector);

		for (let i = 0; i < elements.length; ++i)
		{
			let element = elements[i];

			const imgs = element.querySelectorAll('img');

			for (let j = 0; j < imgs.length; ++j)
			{
				imgs[j].setAttribute('alt', '');
			}
		}

	}

	static disableContextMenu() {

		document.body.contextmenu(function()
		{
			return false;
		});

	}

	static shuffle (array) {

		for (let i = array.length - 1; i > 0; i--)
		{
			const j = Math.floor(Math.random() * (i + 1));

			[array[i], array[j]] = [array[j], array[i]];
		}

		return array;

	}

	static getValue (value, defaultValue) {

		return (value !== undefined)? value : defaultValue;

	}

	static cleanPath (path) {

		return path.split('#')[0];

	}

	static capitalize (string) {

		return string.charAt(0).toUpperCase() + string.slice(1);

	}

	static get (url) {

		return new Promise( (resolve, reject) =>
		{
			let xhr = new window.XMLHttpRequest();

			xhr.onreadystatechange = () =>
			{
				if (xhr.readyState === 4)
				{
					if (xhr.status === 200)
					{
						resolve(xhr.responseXML);
					} else
					{
						reject(xhr);
					}
				}
			};

			xhr.open('GET', url, true);
			xhr.responseType = 'document';
			xhr.send();
		});

	}

}
