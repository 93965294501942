
export default class FluidIframes {

	constructor(selector) {

		this.selector = selector;

		this.DOM = {};

	}

	/*
	** load
	*/

	load() {

		// element
		this.DOM.element = document.querySelector(this.selector);

		if (this.DOM.element === null)
		{
			return false;
		}

		// iframes
		this.DOM.iframes = this.DOM.element.getElementsByTagName('iframe');

		this.DOM.count = this.DOM.iframes.length;

		for (let i = 0; i < this.DOM.count; ++i)
		{
			const iframe = this.DOM.iframes[i];
			const width = iframe.getAttribute('width');
			const height = iframe.getAttribute('height');
			const ratio = ( height / width ) * 100;

			iframe.style.position = 'absolute';
			iframe.style.top = '0';
			iframe.style.left = '0';
			iframe.style.width = '100%';
			iframe.style.height = '100%';

			const wrap = document.createElement('div');

			wrap.className = 'fluid-vids';
			wrap.style.width = '100%';
			wrap.style.position = 'relative';
			wrap.style.paddingTop = ratio + '%';

			const iframeParent = iframe.parentNode;

			iframeParent.insertBefore( wrap, iframe );

			wrap.appendChild( iframe );
		}

	}

	/*
	** unload
	*/

	unload() {

	}

}