import Page from '../common/Page.js';
import SmoothScroll from '../module/SmoothScroll.js';


export default class SmoothPage extends Page {

	constructor() {

		super();

	}

	/*
	** scrollTo
	*/

	scrollTo (value) {

		this.smoothscroll.scrollTo(value);

	}

	/*
	** init
	*/

	init() {

		// super
		super.init();

		// smoothscroll
		this.smoothscroll = new SmoothScroll();
		this.smoothscroll.load();

	}

	/*
	** unload
	*/

	unload() {

		// super
		super.unload();

		// smoothscroll
		this.smoothscroll.unload();

	}

}