import PubSub from 'pubsub-js';
import Path from '../common//Path.js';
import Util from '../common/Util.js';
import MyPage from '../module/MyPage.js';
import Navigator from '../class/Navigator.js';
import Navigate from '../module//Navigate.js';
import Films from '../module/Films.js';
import Grid from '../module/Grid.js';
import VideoThumbnails from '../module/VideoThumbnails.js';
import FluidIframes from '../module/FluidIframes.js';

import WorkImage  from '../module/work/WorkImage.js';
import WorkFilm   from '../module/work/WorkFilm.js';
import WorkFile   from '../module/work/WorkFile.js';
import WorkFolder from '../module/work/WorkFolder.js';


export default class Work extends MyPage {

	constructor() {

		super();

		this.name = 'work';

		this.boundPageReady = (msg, data) => this.pageReady();

	}

	/*
	** init
	*/

	init() {

		// section
		this.sectionEl = document.querySelector('#section');

		if (this.sectionEl === null)
		{
			return false;
		}

		// pubsub
		this.token = PubSub.subscribe('page.ready', this.boundPageReady);

		// attributes
		this.id = this.sectionEl.getAttribute('data-id');

		this.template = this.sectionEl.getAttribute('data-template');

		// class
		this.DOM.body.classList.add('body--work-'+this.template);

		// work instance
		this.initWorkInstance();

		// navigate
		this.navigate = new Navigate();
		this.navigate.load();

		// navigator
		this.initNavigator();

		// poster
		this.initPoster();

		// fluidiframes
		this.fluidiframes = new FluidIframes('.work__content');
		this.fluidiframes.load();

		// films
		this.films = new Films();
		this.films.load();

		// grid
		this.grid = new Grid();
		this.grid.load();

		// video thumbnails
		this.videoThumbnails = new VideoThumbnails();
		this.videoThumbnails.load();

		// super
		super.init();

	}

	initNavigator () {

		const pagesEl = this.DOM.container.querySelectorAll('.path .anchor');

		if (!pagesEl.length)
		{
			return false;
		}

		const key = pagesEl[0].getAttribute('data-navigator-key');

		const navigatorKey = 'works-' + key;

		const backUrl = pagesEl[0].getAttribute('href');

		// back
		const node = Navigator.getNode(navigatorKey, this.id);

		this.navigate.back(backUrl, node.page);

		// prev
		const prevUrl = Navigator.getPrevUrl(navigatorKey, this.id);

		this.navigate.prev(prevUrl);

		// next
		const nextUrl = Navigator.getNextUrl(navigatorKey, this.id);

		this.navigate.next(nextUrl);

		// id
		Navigator.setValue(navigatorKey + '-id', this.id);

	}

	initWorkInstance () {

		switch (this.template)
		{
			case 'image':
				this.instance = new WorkImage();
				break;

			case 'film':
				this.instance = new WorkFilm();
				break;

			case 'file':
				this.instance = new WorkFile();
				break;

			case 'folder':
				this.instance = new WorkFolder();
				break;
		}

		if (this.instance !== undefined)
		{
			this.instance.load();
		}

	}

	initPoster () {

		const posterEl = document.querySelector('.work__poster');

		if (posterEl !== null)
		{
			posterEl.style.cursor = 'pointer';

			posterEl.addEventListener('click', event =>
			{
				Util.scrollToElement ('.work__content', 1000);

				event.preventDefault();

			}, false);
		}

	}

	/*
	** scroll to selected
	*/

	scrollToSelected() {

		if (this.template !== 'folder')
		{
			return false;
		}

		const keyId = 'works-' + this.id + '-id';

		const id = Navigator.getValue(keyId);

		if (!id)
		{
			return false;
		}

		const element = document.querySelector('.works__item[data-id="'+ id +'"]');

		if (element !== null)
		{
			const bounding = element.getBoundingClientRect();

			const top = bounding.top + ( (bounding.height - Util.windowHeight()) / 2);

			if (this.isMobile)
			{
				Util.scrollTo(top, 0);
			}
			 else
			{
				this.scrollTo(top);
			}
		}

	}

	/*
	** page ready
	*/

	pageReady() {

		this.scrollToSelected();

	}

	/*
	** unload
	*/

	unload() {

		// class
		this.DOM.body.classList.remove('body--work-' + this.template);

		// pubsub
		if (this.token !== undefined)
		{
			PubSub.unsubscribe(this.token);
		}

		// instance
		if (this.instance !== undefined)
		{
			this.instance.unload();
			this.instance = undefined;
		}

		// navigate
		this.navigate.unload();

		// fluidiframes
		this.fluidiframes.unload();

		// films
		this.films.unload();

		// grid
		this.grid.unload();

		// video thumbnails
		this.videoThumbnails.unload();

		// super
		super.unload();

	}

}