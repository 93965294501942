import Image from '../class/Image.js';

export default class Images {

	constructor() {

		this.DOM = {};

		this.items = [];

	}

	/*
	** load
	*/

	load() {

		this.DOM.images = document.querySelectorAll('.image');

		this.count = this.DOM.images.length;

		for (let i = 0; i < this.count; ++i)
		{
			const el = this.DOM.images[i];

			this.items[i] = new Image(el);

			this.items[i].load();
		}

	}

	/*
	** unload
	*/

	unload() {

		for (let i = 0; i < this.count; ++i)
		{
			this.items[i].unload();
		}

	}

}