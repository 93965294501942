import VideoThumbnail from '../class/VideoThumbnail.js';

export default class VideoThumbnails {

	constructor() {

		this.DOM = {};

		this.items = [];

	}

	/*
	** load
	*/

	load() {

		this.DOM.thumbnails = document.querySelectorAll('.thumbnail[data-video="1"]');

		this.count = this.DOM.thumbnails.length;

		for (let i = 0; i < this.count; ++i)
		{
			const el = this.DOM.thumbnails[i];

			this.items[i] = new VideoThumbnail(el);

			this.items[i].load();
		}

	}

	/*
	** unload
	*/

	unload() {

		for (let i = 0; i < this.count; ++i)
		{
			this.items[i].unload();
		}

	}

}