
export default class Navigator {

	static setValue (name, value) {

		if (this.recorder == undefined)
		{
			this.recorder = [];
		}

		this.recorder[name] = value;

	}

	static getValue (name) {

		return Navigator.getRecorder(name);

	}

	static recordFromNodeList (name, selector) {

		if (this.recorder == undefined)
		{
			this.recorder = [];
		}

		let items = [];

		const elements = document.querySelectorAll(selector);

		const count = elements.length;

		for (let i = 0; i < count; ++i)
		{
			const el = elements[i];

			items[i] =
			{
				id   : el.getAttribute('data-id'),
				url  : el.getAttribute('data-url'),
				page : el.getAttribute('data-page')
			};
		}

		this.recorder[name] = items;

	}

	static getNode (name, id) {

		const array = Navigator.getRecorder(name);

		if (array)
		{
			const index = Navigator.getIndexFromKey(name, 'id', id);

			if (index != -1)
			{
				return array[index];
			}
		}

		return false;

	}

	static getPrevUrl (name, id) {

		const array = Navigator.getRecorder(name);

		if (array)
		{
			const count = array.length;

			if (count > 1)
			{
				const index = Navigator.getIndexFromKey(name, 'id', id);

				if (index != -1)
				{
					if (index >= 1)
					{
						return array[index-1].url;
					}
					 else
					{
						return array[count-1].url;
					}
				}
			}
		}

		return false;

	}

	static getNextUrl (name, id) {

		const array = Navigator.getRecorder(name);

		if (array)
		{
			const count = array.length;

			if (count > 1)
			{
				const index = Navigator.getIndexFromKey(name, 'id', id);

				if (index != -1)
				{
					if ( index < (count-1) )
					{
						return array[index+1].url;
					}
					 else
					{
						return array[0].url;
					}
				}
			}
		}

		return false;

	}

	static getIndexFromKey (name, key, value) {

		const array = Navigator.getRecorder(name);

		if (array)
		{
			return array.map( (item) => { return item[key] } ).indexOf( value );
		}
		 else
		{
			return -1;
		}

	}

	static getRecorder (name) {

		if (this.recorder !== undefined)
		{
			if (this.recorder[name] !== undefined)
			{
				return this.recorder[name];
			}
		}

		return false;

	}

}