import Navigator from '../../class//Navigator.js';


export default class WorkFolder {

	/*
	** load
	*/

	load() {

		// navigator
		const id = document.querySelector('.section').getAttribute('data-id');

		// navigator
		this.navigatorKey = 'works-' + id;
		Navigator.recordFromNodeList(this.navigatorKey, '.works__item');

	}

	/*
	** unload
	*/

	unload() {

	}

}