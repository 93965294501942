import PubSub from 'pubsub-js';
import PageEvent from '../common/PageEvent.js';
import Util from '../common/Util.js';

export default class SmoothScroll extends PageEvent {

	constructor() {

		super();

		this.DOM = {};

		this.DOM.body = document.body;

		this.enable = false;

		this.scrollTop = 0;

		this.lastScrollTop = 0;

		this.lerp = (a, b, n) => (1 - n) * a + n * b;

		this.boundEventResize = Util.debounce(event => this.resize(event), 500);

	}

	/*
	** resize
	*/

	resize (event) {

		this.DOM.body.style.height = `${this.DOM.content.scrollHeight}px`;

	}

	/*
	** scroll
	*/

	scroll (event) {

		this.scrollTop = Util.getScrollTop();

	}

	/*
	** render
	*/

	render() {

		this.scrollingSpeed = Math.abs(this.scrollTop - this.lastScrollTop);

		this.lastScrollTop = this.scrollTop;

		this.translationY.current = this.translationY.setValue();

		this.translationY.previous = this.lerp(
			this.translationY.previous,
			this.translationY.current,
			this.translationY.ease
		);

		// scroll
		this._scrollTo(this.translationY.previous);

		// publish
		PubSub.publish('smoothscroll.render', {});

		// render
		this.request = requestAnimationFrame(() => this.render());

	}

	/*
	** scrollTo
	*/

	scrollTo (value) {

		if (!this.enable)
		{
			return false;
		}

		value = Math.max(0, value);

		this.translationY = {
			previous: value,
			current: value,
			ease: 0.1,
			setValue: () => this.scrollTop
		};

		this.lastScrollTop = value;

		Util.setScrollTop(value);

	}

	_scrollTo (value) {

		this.DOM.container.scrollTop = 0;

		this.DOM.content.style.transform = `translate3d(0,${-1*value}px,0)`;

	}

	/*
	** load
	*/

	load () {

		if (Util.isMobile())
		{
			return false;
		}

		this.DOM.container = document.querySelector('.smooth-scroll');

		if (this.DOM.container === null)
		{
			return false;
		}

		this.DOM.content = this.DOM.container.querySelector('div[data-scroll]');

		if (this.DOM.content === null)
		{
			return false;
		}

		this.enable = true;

		// super
		super.load();

		// container
		this.DOM.container.style.position = 'fixed';
		this.DOM.container.style.width = this.DOM.container.style.height = '100%';
		this.DOM.container.style.top = this.DOM.container.style.left = 0;
		this.DOM.container.style.overflow = 'hidden';

		// body
		this.DOM.body.style.height = `${this.DOM.content.scrollHeight}px`;

		// scrolltop
		this.scrollTop = Util.getScrollTop();

		// scroll
		this.scrollTo(this.scrollTop);

		// translationY
		this.translationY =
		{
			previous: 0,
			current: 0,
			ease: 0.1,
			setValue: () => this.scrollTop
		};

		// animationframe
		this.request = requestAnimationFrame( () => this.render() );

	}

	/*
	** unload
	*/

	unload () {

		// super
		super.unload();

		// animationframe
		if (this.request !== undefined)
		{
			cancelAnimationFrame(this.request);

			this.request = undefined;
		}

	}
}