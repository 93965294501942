import Console from '../common/Console.js';
import PageEvent from '../common/PageEvent.js';
import Util from '../common/Util.js';


export default class Film extends PageEvent {

	constructor() {

		super();

		this.DOM = {};

	}

	/*
	** init
	*/

	init () {

		// src
		const src = this.DOM.film.getAttribute('src');

		// url
		var arr = src.split('?');
		var url = arr[0];

		// params
		var params = (arr[1] === undefined) ? 'title=0&byline=0&portrait=0' : arr[1];
		params += '&autoplay=1';
		params += '&loop=0';
		params += '&api=1';

		// src
		this.DOM.film.setAttribute('src', url + '?' + params);

	}

	/*
	** resize
	*/

	resize (event) {

		if (!this.DOM.film.getAttribute('data-original-width') && !this.DOM.film.getAttribute('data-original-height'))
		{
			this.DOM.film.setAttribute('data-original-width' , this.DOM.film.getAttribute('width'));
			this.DOM.film.setAttribute('data-original-height', this.DOM.film.getAttribute('height'));
		}

		const maxWidth = this.DOM.container.clientWidth;
		const maxHeight = this.DOM.container.clientHeight;
		const width = Number(this.DOM.film.getAttribute('data-original-width'));
		const height = Number(this.DOM.film.getAttribute('data-original-height'));
		const size = Util.getAdaptedSize (maxWidth, maxHeight, width, height, false);

		this.DOM.film.style.width = size.width + 'px';
		this.DOM.film.style.height = size.height + 'px';

	}

	/*
	** load
	*/

	load() {

		Console.log('Film::load');

		// element
		this.DOM.element = document.querySelector('.film');

		if (this.DOM.element === null)
		{
			return false;
		}

		// container
		this.DOM.container = this.DOM.element.querySelector('.film__container');

		if (this.DOM.container === null)
		{
			return false;
		}

		// film
		this.DOM.film = this.DOM.container.querySelector('iframe');

		if (this.DOM.film === null)
		{
			return false;
		}

		// super
		super.load();

		// init
		this.init();

		// resize
		this.resize();

	}

	/*
	** unload
	*/

	unload() {

		// super
		super.unload();

	}

}