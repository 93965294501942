import Console from '../common/Console.js';
import Util from '../common/Util.js';
import Player from '@vimeo/player';


export default class Films {

	constructor() {

		this.DOM = {};

		this.items = [];

	}

	/*
	** init
	*/

	init () {

		for (let i = 0; i < this.count; ++i)
		{
			const el = this.DOM.films[i];

			const iframe = el.querySelector('iframe');

			if (iframe === null)
			{
				break;
			}

			const player = new Player(iframe);

			this.items.push(
			{
				'index' : i,
				'element': el,
				'iframe': iframe,
				'player' : player
			});

			// info
			const infoEl = el.querySelector('.films__info');

			infoEl.style.cursor = 'pointer';

			infoEl.addEventListener('click', event =>
			{
				this.play(i);

				return false;

			}, false);

		}

	}

	/*
	** play
	*/

	play (index) {

		for (let i = 0; i < this.count; ++i)
		{
			const el = this.items[i].element;

			const iframe = this.items[i].iframe;

			if (this.items[i].index === index)
			{
				this.items[i].player.play();

				el.classList.add('films__item--play');
			}
			 else
			{
				this.items[i].player.pause();

				el.classList.remove('films__item--play');
			}
		}

	}

	/*
	** load
	*/

	load() {

		Console.log('Films::load');

		// element
		this.DOM.element = document.querySelector('.films');

		if (this.DOM.element === null)
		{
			return false;
		}

		// films
		this.DOM.films = this.DOM.element.querySelectorAll('.films__item');

		this.count = this.DOM.films.length;

		if (!this.count)
		{
			return false;
		}

		// init
		this.init();

	}

	/*
	** unload
	*/

	unload() {

		this.items = [];

	}

}