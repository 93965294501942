import PubSub from 'pubsub-js';
import Util from '../../common/Util.js';
import Swiper from '../../class/Swiper.js';
import SwiperVideoSlide from '../../class/SwiperVideoSlide.js';

export default class WorkImage {

	constructor() {

		this.DOM = {};

		this.boundSlideChange = (msg, data) => this.onSlideChange(msg, data);

	}

	/*
	** init
	*/

	initSwiper() {

		// swiper
		this.swiper = new Swiper('.player #swiper');

		this.pubSubSwiperSlideChange = PubSub.subscribe('swiper.slideChange', this.boundSlideChange);

		this.swiper.load();

	}

	onSlideChange (msg, data) {

		this.initSlides(data.index);

	}

	initSlides (selected) {

		for (let i = 0; i <= this.count; i++)
		{
			const el = this.DOM.slides[i];

			if (el !== undefined)
			{
				const is_video = parseInt(el.getAttribute('data-video'));

				if (is_video)
				{
					if (i === selected)
					{
						if ( el.instance === undefined)
						{
							el.instance = new SwiperVideoSlide(el);
							el.instance.load();
						}
					}
					 else
					{
						if (el.instance !== undefined)
						{
							el.instance.unload();
							el.instance = undefined;
						}
					}

					this.DOM.slides[i] = el;
				}
			}
		}

	}

	initScroll () {

		this.DOM.scroll = document.querySelector('.player__scroll');

		if (this.DOM.scroll !== null)
		{
			this.DOM.scroll.style.cursor = 'pointer';

			this.DOM.scroll.addEventListener('click', event =>
			{
				Util.scrollToElement('.work__content', 0);

				event.preventDefault();

			}, false);
		}

	}

	initImages () {

		this.DOM.images  = document.querySelectorAll('.images__item');

		const count = this.DOM.images.length;

		for (let i = 0; i < count; ++i)
		{
			const el = this.DOM.images[i];

			el.style.cursor = 'pointer';

			el.addEventListener('click', event =>
			{
				this.swiper.slideTo(i, 0);

				Util.scrollToElement('#section', 0);

				event.preventDefault();

			}, false);
		}

	}

	/*
	** load
	*/

	load() {

		// slides
		this.DOM.slides = [...document.querySelectorAll('.swiper-slide')];

		// count
		this.count = this.DOM.slides.length;

		// swiper
		this.initSwiper();

		// scroll
		this.initScroll();

		// images
		this.initImages();

	}

	/*
	** unload
	*/

	unload() {

		// unload slides
		this.initSlides(-1);

		// pubsub
		if (this.pubSubSwiperSlideChange !== undefined)
		{
			PubSub.unsubscribe(this.pubSubSwiperSlideChange);
		}

		// swiper
		if (this.swiper !== undefined)
		{
			this.swiper.unload();
			this.swiper = undefined;
		}

	}

}