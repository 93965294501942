
export default class WorkFile {

	/*
	** load
	*/

	load() {

	}

	/*
	** unload
	*/

	unload() {

	}

}